<template>
    <transition name="fade-transition" leave-absolute mode="out-in">
        <router-view></router-view>
    </transition>
</template>
<script>
export default {
    beforeCreate(){
        if(this.$store.getters['auth/getUserData'] === null){
          this.$router.push('/admin/auth/login')
      }

    }


}
</script>